body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdp {
  --rdp-cell-size: 48px !important;
  --rdp-caption-font-size: 14px !important;

  margin: 12px 24px 32px !important;
}
.rdp-nav {
  display: contents;
}
.rdp-nav_button {
  --rdp-cell-size: 20px;
}
.rdp-nav_button_previous {
  order: -1;
}
.rdp-head_cell::after {
  content: '.';
}

.tooltip {
  background: rgba(0, 0, 0, 0.9);

  color: #ffffff;
  font-size: 12px;

  max-width: 400px;
  padding: 16px;
  border-radius: 4px;
}
.tooltip.sm {
  padding: 8px;
}
